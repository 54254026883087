import { useCallback, useState, memo, useMemo } from 'react'

import { ThemeProvider, Modal } from '@guidde/design-system'
import { Box } from '@mui/material'

import {
    SpacesListWithFolders,
    FooterControlButtons,
    Header,
    SearchInput,
    CreateFirstSpaceMessage,
    CreateNewTargetInput,
    NoSearchResults
} from './components'

import { initialSpaceValues, type SubmitDataType } from 'UI/Routes/spaces/dialogs/common'

import { type PlaybookModeType } from 'app/types'

import { logToAnalytics } from 'modules'
import {
    useAuth,
    useBoolean,
    useDataMutation,
    useNotification,
    usePlaybookShare,
    useShareableOptions,
    useTextDebounce
} from 'hooks'

import {
    type TSelectedData,
    checkIfSomeFolderIsUpdated,
    generateInitialState,
    getDataWithActiveFolderInput,
    getDataWithClosedFolderInput,
    getDataWithoutOpenedFolderInputs,
    getSelectedDataWithToggledCheckbox,
    getDataWithToggledFolderInput,
    type TAddCreatedSpaceIntoSelectedData,
    addCreatedSpaceIntoSelectedData,
    addCreatedFolderIntoSelectedData,
    type TAddCreatedFolderIntoSelectedData,
    generateDataForSave,
    getToggleSpaceSelectData,
    sortSpacesBySpaceId,
    generateSpaceOwnerOption,
    checkIfSomeSpaceIsUpdated
} from './utils'

type CreateSpacePayload = SubmitDataType

type CreateFolderPayload = {
    name: string
    spaceId: string
    parentFolderId: string
}
export type CreateFolderResponse = { folderId?: string; message: string }

type Props = {
    isOpen: boolean
    onClose: () => void
    playbooksIds: Array<string>
    playbookMode?: PlaybookModeType
}

export const NewShareToSpaceDialog = memo(
    ({ isOpen, playbooksIds, playbookMode, onClose }: Props) => {
        const { email = '', uid = '', photoURL = '', displayName = '' } = useAuth()

        const { showSuccessNotification } = useNotification()

        const showNewSpaceInput = useBoolean()

        const folderSelectionChanged = useBoolean()
        const spaceSelectionChanged = useBoolean()

        const setFolderSelectionChanged = folderSelectionChanged.set
        const setSpaceSelectionChanged = spaceSelectionChanged.set

        const [spaceSearch, setSpaceSearchValue] = useState('')
        const [createdSpaceId, setCreatedSpaceId] = useState('')
        const [selectedData, setSelectedData] = useState<TSelectedData>({})

        const debouncedText = useTextDebounce(spaceSearch, 1000)

        const $options = useShareableOptions({
            playbooksIds,
            params: {
                spaces: true,
                spaceSearch: debouncedText.trim()
            },
            onSuccess: ({ spaces }) => {
                if (Object.values(selectedData).length) return

                setSelectedData(generateInitialState(spaces))
            },
            onError: () => handleClose()
        })

        const handleClose = useCallback(() => {
            setSelectedData({})
            setCreatedSpaceId('')
            setSpaceSearchValue('')
            onClose()
        }, [onClose])

        const handleOnSuccessCreate = async () => {
            showNewSpaceInput.setFalse()
            setSpaceSearchValue('')

            await $options.mutate()
        }

        const $createFolder = useDataMutation<CreateFolderPayload, CreateFolderResponse, Error>(
            '/c/v1/space/folder',
            'POST',
            {
                onSuccess: async (response, payload) => {
                    if (!response?.folderId) return

                    await handleOnSuccessCreate()

                    const folderData = {
                        id: response.folderId,
                        name: payload.name,
                        parentFolderId: payload.parentFolderId,
                        createdBy: {
                            uid,
                            photoURL,
                            displayName
                        }
                    }
                    insertCreatedFolder({
                        spaceId: payload.spaceId,
                        folderData
                    })

                    setFolderSelectionChanged(true)
                },
                onFailure: console.error
            }
        )

        const $createSpace = useDataMutation<CreateSpacePayload, { spaceId: string }, Error>(
            '/c/v1/space/create',
            'POST',
            {
                onSuccess: async (response, payload) => {
                    if (!response?.spaceId) return

                    setCreatedSpaceId(response.spaceId)
                    await handleOnSuccessCreate()
                    insertCreatedSpace({ id: response.spaceId, name: payload.name })

                    setSpaceSelectionChanged(true)
                },
                onFailure: console.error
            }
        )
        const insertCreatedFolder = useCallback((folderData: TAddCreatedFolderIntoSelectedData) => {
            setSelectedData(prevData => {
                return addCreatedFolderIntoSelectedData(prevData, folderData)
            })
        }, [])

        const insertCreatedSpace = useCallback((spaceData: TAddCreatedSpaceIntoSelectedData) => {
            setSelectedData(prevData => {
                return addCreatedSpaceIntoSelectedData(prevData, spaceData)
            })
        }, [])

        const { sharePlaybook, isSharing, ApprovalModal } = usePlaybookShare({
            onSuccess: data => {
                if (data?.code === 422) return

                showSuccessNotification('Success')
                handleClose()
            },
            onFailure: console.error
        })

        const handleOnSave = async () => {
            const { spaceIds, folderIds, folderNames, spaceNames, result } =
                generateDataForSave(selectedData)

            logToAnalytics('spaces_addToSpaceSaveBtn_clicked', {
                spaceIds,
                folderIds,
                folderNames,
                spaceNames,
                playbooksIds
            })

            await sharePlaybook({
                playbooksIds,
                spacesIds: result,
                shareMode: 'overwriteByOption'
            })
        }

        const handleCreateNewFolder = (payload: CreateFolderPayload) => {
            logToAnalytics('folders_newFolderSaveBtn_clicked', {
                ...payload,
                source: 'addToSpaceDialog'
            })

            return $createFolder.mutate(payload)
        }

        const toggleSpaceSelect = useCallback(
            (spaceId: string) => {
                setSelectedData(prevData => {
                    const newSelectData = getToggleSpaceSelectData(prevData, spaceId)
                    const isSomeSpaceUpdated = checkIfSomeSpaceIsUpdated(
                        newSelectData,
                        $options.data.spaces
                    )

                    setSpaceSelectionChanged(isSomeSpaceUpdated)

                    return newSelectData
                })
            },
            [$options.data.spaces, setSpaceSelectionChanged]
        )

        const onShowFolderInputClick = useCallback((spaceId: string) => {
            setSelectedData(prev => getDataWithActiveFolderInput(prev, spaceId))
        }, [])

        const closeNewFolderInput = useCallback(() => {
            setSelectedData(prev => getDataWithClosedFolderInput(prev))
        }, [])

        const toggleFolderCheckbox = useCallback(
            (spaceId: string, folderId: string) => {
                setSelectedData(prevData => {
                    const newSelectedData = getSelectedDataWithToggledCheckbox({
                        selectedData: prevData,
                        folderId,
                        spaceId
                    })

                    const isSomeFolderUpdated = checkIfSomeFolderIsUpdated(
                        newSelectedData,
                        $options.data.spaces
                    )

                    const isSomeSpaceUpdated = checkIfSomeSpaceIsUpdated(
                        newSelectedData,
                        $options.data.spaces
                    )

                    setFolderSelectionChanged(isSomeFolderUpdated)
                    setSpaceSelectionChanged(isSomeSpaceUpdated)

                    return newSelectedData
                })
            },
            [setFolderSelectionChanged, setSpaceSelectionChanged, $options.data.spaces]
        )

        const onToggleNewFolderInput = useCallback((folderId: string) => {
            setSelectedData(prevData => {
                return getDataWithToggledFolderInput({ selectedData: prevData, folderId })
            })
        }, [])

        const onShowNewSpaceInput = () => {
            setSpaceSearchValue('')
            setSelectedData(prev => getDataWithoutOpenedFolderInputs(prev))

            showNewSpaceInput.setTrue()
        }

        const createNewSpace = (spaceName: string) => {
            if (!email || !uid) return

            logToAnalytics('spaces_newSpaceSaveBtn_clicked', {
                spaceName,
                source: 'addToSpaceDialog'
            })

            const ownerOption = generateSpaceOwnerOption({ email, uid })

            $createSpace.mutate({
                ...initialSpaceValues,
                name: spaceName,
                members: [ownerOption]
            })
        }

        const sortedSpaces = useMemo(() => {
            if (!createdSpaceId) return $options.data.spaces

            return sortSpacesBySpaceId($options.data.spaces, createdSpaceId)
        }, [$options.data.spaces, createdSpaceId])

        const spacesExists = Number($options.data?.spaces?.length) > 0 || $options.isValidating

        const canShowMessages =
            (!$options.isValidating || spacesExists) && showNewSpaceInput.isFalse

        const searchValueExist = !!spaceSearch.trim()
        const showNoSearchResult = canShowMessages && searchValueExist && !spacesExists

        const showCreateNewSpaceMessage = canShowMessages && !spacesExists && !showNoSearchResult
        const showControlButtons =
            spacesExists || showNewSpaceInput.isTrue || showNoSearchResult || searchValueExist

        const someRequestIsInPendingStatus =
            $createFolder.isLoading || $createSpace.isLoading || isSharing

        const isDataChanged = folderSelectionChanged.isTrue || spaceSelectionChanged.isTrue

        const disableSave = someRequestIsInPendingStatus || !isDataChanged

        if (!isOpen) return null

        return (
            <>
                {ApprovalModal}

                <ThemeProvider>
                    <Modal
                        isOpen={isOpen}
                        onClose={handleClose}
                        size="md"
                        isClosableOnClickOutside={false}
                        useDefaultCloseButton={false}
                    >
                        <Box height={510} display="flex" flexDirection="column" py={2.5}>
                            <Box sx={{ p: 0, px: 2.5 }}>
                                <Header onClose={handleClose} />
                                <Box mt={3} />

                                <SearchInput
                                    onChange={value => {
                                        setSpaceSearchValue(value)
                                        showNewSpaceInput.setFalse()
                                    }}
                                    value={spaceSearch}
                                />

                                {showNewSpaceInput.isTrue && (
                                    <Box mt={2.5}>
                                        <CreateNewTargetInput
                                            onCreate={createNewSpace}
                                            target="space"
                                            onDiscard={showNewSpaceInput.setFalse}
                                        />
                                    </Box>
                                )}
                            </Box>

                            {showCreateNewSpaceMessage && (
                                <CreateFirstSpaceMessage
                                    onCreateSpace={showNewSpaceInput.setTrue}
                                />
                            )}
                            {showNoSearchResult && <NoSearchResults />}

                            {showNewSpaceInput.isTrue && !spacesExists && (
                                <Modal.Body sx={{ p: 0, height: '100%' }} />
                            )}

                            {spacesExists && (
                                <Modal.Body sx={{ p: 0, px: 2.5, height: '100%' }}>
                                    <SpacesListWithFolders
                                        spaces={sortedSpaces || []}
                                        selectedData={selectedData}
                                        isMultiplePlaybook={playbooksIds.length > 1}
                                        playbookMode={playbookMode}
                                        toggleSpaceSelect={toggleSpaceSelect}
                                        onCloseNewFolderInput={closeNewFolderInput}
                                        onShowFolderInputClick={onShowFolderInputClick}
                                        onToggleFolderCheckbox={toggleFolderCheckbox}
                                        onToggleNewFolderInput={onToggleNewFolderInput}
                                        onCreateNewFolder={handleCreateNewFolder}
                                    />
                                </Modal.Body>
                            )}

                            {showControlButtons && (
                                <Modal.Footer sx={{ p: 0, px: 2.5 }}>
                                    <FooterControlButtons
                                        onCancel={handleClose}
                                        onCreateSpace={onShowNewSpaceInput}
                                        onSave={handleOnSave}
                                        disableSave={disableSave}
                                        disableCreateSpace={showNewSpaceInput.isTrue}
                                    />
                                </Modal.Footer>
                            )}
                        </Box>
                    </Modal>
                </ThemeProvider>
            </>
        )
    }
)
