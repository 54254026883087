import { memo, useMemo } from 'react'
import { mutate } from 'swr'

import { Avatar, Box, ClickAwayListener, Link, ListItem, List, Stack } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FlagIcon from '@mui/icons-material/FlagOutlined'

import { getStepData, VerticalToolbar } from 'UI/Components'

import { StepTitle } from './StepTitle'
import { MagicDescription } from './MagicDescription'
import { StepDescription } from './StepDescription'
import { StepScreenshot } from './StepScreenshot'
import { StepGoTo } from './StepGoTo'
import { AddStep } from './AddStep'
import { StepCTAPreview } from './StepCTAPreview'

import { useBoolean, useDataMutation } from 'hooks'
import { type QuickGuiddeType, type StepType, Shape } from 'app/types'
import { delay, logToAnalytics } from 'modules'

type Props = {
    originalIndex: number
    step: StepType
    stepNumber: string
    canEdit: boolean
    showGoToOnly: boolean
    stepNumberingColor: string
    showMagicAnimation?: boolean
    brandKitHighlightColor?: string
    playbook: QuickGuiddeType
    isRtl?: boolean
}

export const DocStep = memo(
    ({
        originalIndex,
        stepNumberingColor,
        step,
        stepNumber,
        canEdit,
        showGoToOnly,
        showMagicAnimation,
        brandKitHighlightColor,
        playbook,
        isRtl = false
    }: Props) => {
        const { id: playbookId, steps } = playbook

        const { kind, id: stepId, url, cta, description, layers } = step

        const { stepTitle, stepGoTo } = useMemo(() => getStepData(step), [step])

        const hover = useBoolean()
        const edit = useBoolean()
        const showCopy = useBoolean()

        const hasVideoLayer = layers.some(layer => layer.type === Shape.Video)

        const $editStep = useDataMutation('/c/v1/quickguidde/editDocStep', 'PUT', {
            // be careful with this key, if one day we change url on PageViewPlaybook, this key should be updated as well
            onSuccess: () => mutate([`/c/v1/quickguidde?id=${playbookId}&view=true`])
        })

        const updateStep = (
            updatedField: Partial<{
                title: string
                url: string
                docScreenshot: string
                description: string
            }>
        ) => {
            return $editStep.mutate({ playbookId, stepId, ...updatedField }).then(() => {
                logToAnalytics('qg-inline-edit', {
                    objectType: Object.keys(updatedField)[0],
                    objectValue: Object.values(updatedField)[0],
                    playbookId,
                    stepId
                })
            })
        }

        const stepLabel = useMemo(() => {
            const CoverIcon = isRtl ? ArrowBackIcon : ArrowForwardIcon

            const labels = {
                intro: 'Intro',
                cover: <CoverIcon fontSize="small" />,
                step: stepNumber,
                end: <FlagIcon fontSize="small" />,
                outro: 'Outro'
            }

            if (
                canEdit &&
                edit.isFalse &&
                !description?.length &&
                (kind === 'cover' || kind === 'end')
            ) {
                return (
                    <Box
                        px={1.5}
                        py={0.5}
                        borderRadius="12px"
                        bgcolor={hover.isTrue ? '#FED243' : 'gainsboro'}
                        sx={{ maxWidth: '250px' }}
                    >
                        {labels[kind]} Add {kind === 'cover' ? 'opening' : 'closing'} paragraph
                    </Box>
                )
            }

            return (
                <Avatar
                    sx={{
                        background: brandKitHighlightColor,
                        marginLeft: isRtl ? '5px' : 0,
                        marginTop: '2px',
                        width: 24,
                        height: 24,
                        maxHeight: 24,
                        color: stepNumberingColor,
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }}
                >
                    {labels[kind]}
                </Avatar>
            )
        }, [
            isRtl,
            stepNumber,
            canEdit,
            edit.isFalse,
            description?.length,
            kind,
            brandKitHighlightColor,
            stepNumberingColor,
            hover.isTrue
        ])

        // Do not render empty cover/end step for viewer
        if (!showMagicAnimation && !canEdit && kind !== 'step' && !description) return null

        return (
            <ClickAwayListener
                onClickAway={() => {
                    edit.setFalse()
                    hover.setFalse()
                }}
            >
                {/* onMouseLeave should be on higher level to cover 'Add Step' sections */}
                <Box
                    position="relative"
                    onMouseLeave={canEdit ? () => hover.setFalse() : undefined}
                >
                    {!showGoToOnly && kind === 'step' && (
                        <AddStep
                            placement="before"
                            playbookId={playbookId}
                            stepIndex={originalIndex}
                            visible={hover.isTrue && edit.isFalse}
                            top={-14}
                        />
                    )}

                    <Box
                        // Please do not remove id, it is used as a scroll anchor in the QGNavigation
                        id={stepId}
                        mb={1}
                        px={3}
                        py={2}
                        width="100%"
                        sx={{
                            border: '2px solid transparent',
                            ...((hover.isTrue || edit.isTrue) && {
                                borderRadius: '6px',
                                border: '2px solid #FED243 !important'
                            }),
                            ...(hover.isTrue &&
                                !edit.isTrue && {
                                    backgroundColor: '#FEFAE4'
                                })
                        }}
                        position="relative"
                        onMouseEnter={canEdit ? () => hover.setTrue() : undefined}
                    >
                        {hover.isTrue && edit.isFalse && (
                            <VerticalToolbar
                                playbook={playbook}
                                step={step}
                                isGoToStep={showGoToOnly}
                                onEdit={edit.setTrue}
                            />
                        )}

                        {showGoToOnly ? (
                            <StepGoTo
                                hovered={hover.isTrue}
                                isEditMode={edit.isTrue}
                                stepGoTo={stepGoTo}
                                playbook={playbook}
                                link={url}
                                onUpdate={newLink => updateStep({ url: newLink })}
                            />
                        ) : (
                            <>
                                <Stack
                                    mb={1}
                                    minHeight={30}
                                    spacing={edit.isTrue ? 2 : 1}
                                    direction={edit.isTrue ? 'column' : 'row'}
                                    alignItems="flex-start"
                                    onMouseOver={showCopy.setTrue}
                                    onMouseLeave={showCopy.setFalse}
                                >
                                    <Box
                                        width="auto !important"
                                        style={{ marginRight: isRtl ? '8px' : '0' }}
                                    >
                                        <bdi>{stepLabel}</bdi>
                                    </Box>

                                    {/* Title field */}
                                    {kind === 'step' && (
                                        <StepTitle
                                            stepId={stepId}
                                            showCopy={showCopy.isTrue}
                                            isEditMode={edit.isTrue}
                                            title={stepTitle}
                                            onUpdate={newTitle => updateStep({ title: newTitle })}
                                        />
                                    )}
                                </Stack>

                                {/* Description field */}
                                {showMagicAnimation ? (
                                    <MagicDescription
                                        index={originalIndex}
                                        description={description || ''}
                                    />
                                ) : (
                                    <StepDescription
                                        isEditMode={edit.isTrue}
                                        note={description || ''}
                                        onUpdate={newText => {
                                            updateStep({
                                                description: newText
                                            })
                                        }}
                                    />
                                )}

                                {cta?.ctaType === 'single' && cta?.action.enabled && (
                                    <StepCTAPreview cta={cta.action} playbook={playbook} />
                                )}

                                {cta?.ctaType === 'multiple' && (
                                    <>
                                        <Box
                                            mt={3}
                                            fontWeight="700"
                                            fontSize="16"
                                            letterSpacing="0.15"
                                        >
                                            {cta.title}
                                        </Box>
                                        <List
                                            sx={{
                                                margin: '8px 0',
                                                '& li': {
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                },
                                                '& li::before': {
                                                    content: '""',
                                                    width: '4px',
                                                    height: '4px',
                                                    backgroundColor: '#2D9CDB',
                                                    borderRadius: '50%',
                                                    display: 'inline-block',
                                                    flexShrink: 0,
                                                    marginRight: '8px'
                                                }
                                            }}
                                        >
                                            {cta.actions.map((action, idx) => (
                                                <ListItem key={idx}>
                                                    <StepCTAPreview
                                                        cta={action}
                                                        playbook={playbook}
                                                        margin={0}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </>
                                )}

                                {kind === 'step' && (
                                    <StepScreenshot
                                        index={originalIndex}
                                        playbookId={playbookId}
                                        isEditMode={edit.isTrue}
                                        step={step}
                                        hasVideoLayer={hasVideoLayer}
                                        stepLabel={stepLabel}
                                        isRtl={isRtl}
                                        onUpdate={url => updateStep({ docScreenshot: url })}
                                    />
                                )}

                                {hasVideoLayer && (
                                    <Link
                                        mt={1}
                                        onClick={() => {
                                            const video = document.querySelector('video')
                                            if (!video) return

                                            const stepTime = steps.reduce((acc, step, idx) => {
                                                if (idx >= originalIndex) return acc
                                                const { duration, transition } = step
                                                return acc + duration + (transition?.duration || 0)
                                            }, 0)

                                            window.scrollTo({
                                                top: 0,
                                                behavior: 'smooth'
                                            })
                                            video.currentTime = stepTime
                                            // Wait for page to scroll to the top
                                            delay(1000).then(() => video.play())
                                        }}
                                        sx={{ cursor: 'pointer' }}
                                        color="#2D9CDB"
                                        fontWeight={500}
                                    >
                                        Jump to this step in video
                                    </Link>
                                )}
                            </>
                        )}
                    </Box>

                    {!showGoToOnly && kind === 'step' && (
                        <AddStep
                            placement="after"
                            playbookId={playbookId}
                            stepIndex={originalIndex}
                            visible={hover.isTrue && edit.isFalse}
                            bottom={-14}
                        />
                    )}
                </Box>
            </ClickAwayListener>
        )
    }
)
