import { useCallback, useMemo } from 'react'

import { useQuery, useDataMutation } from 'hooks'
import { type SpeakerType } from 'app/types'

type TUserPreferences = {
    // #TODO: Delete this type as soon as the API is ready https://guidde.atlassian.net/browse/GD-10421
    lastSpeakerIdUsed?: string
    speaker?: TSpeaker
} & TAudioNoteSettings

type TUserLanguageSpeaker = {
    defaultSpeakerId: string
    recentlyUsedSpeakerIds: Array<string>
}

type TSpeaker = {
    lastLanguageUsed: string
    languages: Record<string, TUserLanguageSpeaker>
}

type TUpdateDefaultSpeakerPayload = {
    langCode: string
    speakerId: string
}

type TUpdateSpeakerSelectionPayload = {
    selectedSpeaker: SpeakerType
}

type TAudioNoteSettings = {
    audioNote?: {
        applyToSubtitles?: boolean
        applyToDescription?: boolean
    }
}

export const useServerUserPreferences = () => {
    const $serverUserPreferences = useQuery<TUserPreferences>('/c/v1/user-preferences', {
        method: 'GET'
    })

    const $setDefaultSpeaker = useDataMutation<
        TUpdateDefaultSpeakerPayload,
        TUserPreferences,
        Error
    >('/c/v1/user-preferences/default-speaker', 'POST', {
        onSuccess: () => $serverUserPreferences.mutate()
    })

    const $updateSpeakerSelection = useDataMutation<
        TUpdateSpeakerSelectionPayload,
        TUserPreferences,
        Error
    >('/c/v1/user-preferences/speaker-selection', 'POST', {
        onSuccess: () => $serverUserPreferences.mutate()
    })
    const $updateAudioNoteSettings = useDataMutation<
        TAudioNoteSettings['audioNote'],
        TUserPreferences,
        Error
    >('/c/v1/user-preferences/audio-note', 'PATCH', {
        onSuccess: () => $serverUserPreferences.mutate()
    })

    const setDefaultSpeaker = useCallback(
        (speaker: SpeakerType) => {
            return $setDefaultSpeaker.mutate({
                speakerId: speaker.id,
                langCode: speaker.langCode
            })
        },
        [$setDefaultSpeaker]
    )

    const updateSpeakerSelection = useCallback(
        (speaker: SpeakerType) => {
            return $updateSpeakerSelection.mutate({
                selectedSpeaker: speaker
            })
        },
        [$updateSpeakerSelection]
    )

    const updateAudioNoteSettings = useCallback(
        (voiceoverSettings: TAudioNoteSettings['audioNote'] = {}) => {
            return $updateAudioNoteSettings.mutate(voiceoverSettings)
        },
        [$updateAudioNoteSettings]
    )

    return useMemo(
        () => ({
            isServerUserPreferencesLoading: $serverUserPreferences.isValidating,
            serverUserPreferences: $serverUserPreferences.data || ({} as TUserPreferences),
            setDefaultSpeaker,
            updateSpeakerSelection,
            updateAudioNoteSettings
        }),
        [
            $serverUserPreferences.isValidating,
            $serverUserPreferences.data,
            setDefaultSpeaker,
            updateSpeakerSelection,
            updateAudioNoteSettings
        ]
    )
}
