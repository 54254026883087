import { type ShareableSpaceType, type PlaybookModeType } from 'app/types'

import { type FolderType } from 'hooks'
import { SPACE_ROLES } from 'modules'

type TSelectedDataSpace = Omit<ShareableSpaceType, 'folders'> & {
    showNewFolderInput?: boolean
    folders: Array<TSelectedDataFolder>
}

export type TSelectedData = {
    [spaceId: string]: TSelectedDataSpace
}

export type TSelectedDataFolder = FolderType & {
    showNewFolderInput?: boolean
    isDisabled?: boolean
}

export const mapFoldersToOneLevel = (folders: Array<FolderType> = []): Array<FolderType> => {
    const result: Array<FolderType> = []

    for (const folder of folders) {
        result.push(folder)

        // Recursively search in nested folders
        result.push(...mapFoldersToOneLevel(folder.nestedFolders))
    }

    return result
}

export const generateInitialState = (spaces: Array<ShareableSpaceType>) => {
    const initialState: TSelectedData = {}

    for (const space of spaces) {
        const oneLevelFolders = mapFoldersToOneLevel(space.folders)

        const selectedFolder = oneLevelFolders.find(folder => folder.isSelected)

        const folders = selectedFolder
            ? oneLevelFolders.map(folder => ({
                  ...folder,
                  isDisabled: folder.id !== selectedFolder.id
              }))
            : oneLevelFolders

        initialState[space.id] = {
            ...space,
            folders
        }
    }

    return initialState
}

export const getDataWithActiveFolderInput = (
    selectedData: TSelectedData,
    activeSpaceId: string
): TSelectedData => {
    return Object.entries(selectedData).reduce(
        (acc, [spaceId, space]) => ({
            ...acc,
            [spaceId]: {
                ...space,
                showNewFolderInput: spaceId === activeSpaceId,
                folders: getFoldersWithClosedInputs(space.folders)
            }
        }),
        {}
    )
}

export const getDataWithClosedFolderInput = (selectedData: TSelectedData): TSelectedData => {
    return Object.entries(selectedData).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: {
                ...value,
                showNewFolderInput: false
            }
        }),
        {}
    )
}

const getFoldersWithClosedInputs = (
    folders: Array<TSelectedDataFolder>
): Array<TSelectedDataFolder> => {
    return folders.map(folder => ({ ...folder, showNewFolderInput: false }))
}

export const getDataWithoutOpenedFolderInputs = (selectedData: TSelectedData): TSelectedData => {
    return Object.entries(selectedData).reduce(
        (acc, [spaceId, space]) => ({
            ...acc,
            [spaceId]: {
                ...space,
                showNewFolderInput: false,
                folders: getFoldersWithClosedInputs(space.folders)
            }
        }),
        {}
    )
}
type TGetDataWithToggledFolderInput = {
    selectedData: TSelectedData
    folderId: string
}

export const getDataWithToggledFolderInput = ({
    selectedData,
    folderId
}: TGetDataWithToggledFolderInput): TSelectedData => {
    return Object.entries(selectedData).reduce(
        (acc, [spaceId, space]) => ({
            ...acc,
            [spaceId]: {
                ...space,
                showNewFolderInput: false,
                folders: space.folders.map(folder => {
                    if (folder.id === folderId) {
                        return {
                            ...folder,
                            showNewFolderInput: !folder.showNewFolderInput
                        }
                    }

                    return { ...folder, showNewFolderInput: false }
                })
            }
        }),
        {}
    )
}

type TGetSelectedDataWithToggledCheckbox = {
    selectedData: TSelectedData
    folderId: string
    spaceId: string
}

export const getSelectedDataWithToggledCheckbox = ({
    selectedData,
    folderId,
    spaceId
}: TGetSelectedDataWithToggledCheckbox) => {
    const space = selectedData[spaceId]
    const folders = space.folders

    const targetFolder = space.folders.find(folder => folder.id === folderId)
    const shouldCurrentSelectFolder = !targetFolder?.isSelected

    const updatedFolders = folders.map(folder => {
        if (!targetFolder) return folder

        const isCurrentFolder = folder.id === folderId
        if (!isCurrentFolder) return { ...folder, isDisabled: shouldCurrentSelectFolder }

        return { ...folder, isSelected: shouldCurrentSelectFolder, isDisabled: false }
    })

    return {
        ...selectedData,
        [spaceId]: {
            ...space,
            isSelected: false,
            folders: updatedFolders
        }
    }
}

export type TAddCreatedSpaceIntoSelectedData = { name: string; id: string }

export const addCreatedSpaceIntoSelectedData = (
    selectedData: TSelectedData,
    createdSpaceData: TAddCreatedSpaceIntoSelectedData
) => {
    const newSpace: TSelectedDataSpace = {
        isDisabled: false,
        isSelected: true,
        logo: '',
        folders: [],
        ...createdSpaceData
    }

    return {
        ...selectedData,
        [createdSpaceData.id]: newSpace
    }
}

export type TAddCreatedFolderIntoSelectedData = {
    spaceId: string
    folderData: {
        id: string
        name: string
        parentFolderId: string
        createdBy: FolderType['createdBy']
    }
}
export const addCreatedFolderIntoSelectedData = (
    selectedData: TSelectedData,
    createdFolderData: TAddCreatedFolderIntoSelectedData
) => {
    const newFolder: TSelectedDataFolder = {
        videosIds: [],
        isSelected: true,
        nestedFolders: [],
        ...createdFolderData.folderData
    }

    const data = getDataWithToggledFolderInput({
        selectedData,
        folderId: createdFolderData.folderData.parentFolderId
    })

    const space = data[createdFolderData.spaceId]

    return {
        ...data,
        [space.id]: {
            ...space,
            folders: [...space.folders.map(space => ({ ...space, isDisabled: true })), newFolder]
        }
    }
}

export const getToggleSpaceSelectData = (selectedData: TSelectedData, spaceId: string) => {
    const space = selectedData[spaceId]

    if (!space) return selectedData

    return {
        ...selectedData,
        [spaceId]: {
            ...space,
            isSelected: !space.isSelected
        }
    }
}

type TGenerateDataForSave = {
    spaceIds: Array<string>
    folderIds: Array<string>
    folderNames: Array<string>
    spaceNames: Array<string>
    result: Array<string>
}
export const generateDataForSave = (selectedData: TSelectedData): TGenerateDataForSave => {
    const spaceIds: TGenerateDataForSave['spaceIds'] = []
    const folderIds: TGenerateDataForSave['folderIds'] = []
    const folderNames: TGenerateDataForSave['folderNames'] = []
    const spaceNames: TGenerateDataForSave['spaceNames'] = []
    const result: TGenerateDataForSave['result'] = []

    Object.values(selectedData).forEach(space => {
        if (space.isSelected) {
            spaceIds.push(space.id)
            spaceNames.push(space.name)

            const isSomeFolderSelected = space.folders.some(folder => folder.isSelected)

            if (!isSomeFolderSelected) result.push(space.id)
        }

        space.folders.forEach(folder => {
            if (!folder.isSelected) return

            folderIds.push(folder.id)
            folderNames.push(folder.name)

            const folderCode = `${space.id}-${folder.id}`
            result.push(folderCode)
        })
    })

    return {
        spaceIds,
        folderIds,
        folderNames,
        spaceNames,
        result
    }
}

export const maxFolderNesting = 3

type TGetTooltipText = {
    isMultiplePlaybook: boolean
    playbookMode?: PlaybookModeType
}

export const getTooltipText = ({ isMultiplePlaybook, playbookMode }: TGetTooltipText) => {
    if (isMultiplePlaybook) {
        return 'Guiddes can’t be in more than one folder within a Space'
    }
    if (playbookMode === 'playlist') {
        return 'A playlist can’t be in more than one folder within a Space'
    }

    return 'A Guidde can’t be in more than one folder within a Space'
}

type TGetCheckBoxTooltipText = {
    isMaxLevelOfNesting: boolean
    isAnotherSpaceFolderSelected: boolean
} & TGetTooltipText

export const getCheckBoxTooltipText = ({
    isMaxLevelOfNesting,
    isAnotherSpaceFolderSelected,
    isMultiplePlaybook,
    playbookMode
}: TGetCheckBoxTooltipText) => {
    if (isMaxLevelOfNesting) return `Limit of ${maxFolderNesting} subfolders reached`
    if (isAnotherSpaceFolderSelected) return getTooltipText({ isMultiplePlaybook, playbookMode })

    return ''
}

type TGetNewFolderButtonTooltipText = {
    isMaxLevelOfNesting: boolean
    isSomeFolderSelected: boolean
} & TGetTooltipText

export const getNewFolderButtonTooltipText = ({
    isMaxLevelOfNesting,
    isSomeFolderSelected,
    isMultiplePlaybook,
    playbookMode
}: TGetNewFolderButtonTooltipText) => {
    if (isMaxLevelOfNesting) return `Limit of ${maxFolderNesting} subfolders reached`
    if (isSomeFolderSelected) return getTooltipText({ isMultiplePlaybook, playbookMode })

    return 'New Folder'
}

export const sortSpacesBySpaceId = (
    spaces: Array<ShareableSpaceType> = [],
    firstSpaceId: string
) => {
    if (!firstSpaceId) return spaces

    // [...spaces] for preventing mutation of the original array
    return [...spaces].sort((a, b) => {
        if (a.id === firstSpaceId) return -1
        if (b.id === firstSpaceId) return 1
        return 0
    })
}

type TGenerateSpaceOwnerOption = { email: string; uid: string }

export const generateSpaceOwnerOption = ({ email, uid }: TGenerateSpaceOwnerOption) => ({
    email,
    uid,
    role: SPACE_ROLES.OWNER
})

const haveSameSelection = (
    array1: Array<{ id: string; isSelected: boolean }>,
    array2: Array<{ id: string; isSelected: boolean }>
) => {
    // Create a map for quick lookup of isSelected values in the second array
    const map = new Map<string, boolean>()
    array2.forEach(item => map.set(item.id, item.isSelected))

    // Check if every item in the first array has the same isSelected value in the second array
    return array1.every(item => map.has(item.id) && map.get(item.id) === item.isSelected)
}

export const checkIfSomeFolderIsUpdated = (
    selectedData: TSelectedData,
    initialData: Array<ShareableSpaceType>
) => {
    const selectedDataFolderStates = Object.values(selectedData).flatMap(space =>
        space.folders.map(folder => ({
            id: folder.id,
            isSelected: Boolean(folder.isSelected)
        }))
    )

    const initialDataFolderStates = initialData.flatMap(space => {
        return mapFoldersToOneLevel(space.folders).map(folder => ({
            id: folder.id,
            isSelected: Boolean(folder.isSelected)
        }))
    })

    const isSomeFolderUpdated = !haveSameSelection(
        initialDataFolderStates,
        selectedDataFolderStates
    )

    return isSomeFolderUpdated
}

export const checkIfSomeSpaceIsUpdated = (
    selectedData: TSelectedData,
    initialData: Array<ShareableSpaceType>
) => {
    const selectedDataSpaceStates = Object.values(selectedData).map(space => ({
        id: space.id,
        isSelected: Boolean(space.isSelected)
    }))

    const initialDataSpaceStates = initialData.map(space => ({
        id: space.id,
        isSelected: Boolean(space?.isSelected)
    }))

    const isSomeSpaceUpdated = !haveSameSelection(selectedDataSpaceStates, initialDataSpaceStates)

    return isSomeSpaceUpdated
}
