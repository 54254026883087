export const firebaseConfig = {
    "projectId": "guidde-dev6",
    "appId": "1:134521531137:web:50945cc4d512abe0780900",
    "databaseURL": "https://guidde-dev6-default-rtdb.firebaseio.com",
    "storageBucket": "guidde-dev6.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyBCVGkFYQyBSmRpCdZDmNG0fhphfZTNIFI",
    "authDomain": "dev6.guidde.com",
    "messagingSenderId": "134521531137",
    "environment": "guidde-dev6"
}

export const stripeKey =
'None'
export const stiggClientKey =
'$2b$10$2tLiBrjgm05excoNtNqQS.PvzVTP.dnsM3fL/Jk77AeedV6moEpIO:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const facebookAppId = '3155065591456766'
export const celloConfig = {
    script: 'https://assets.sandbox.cello.so/app/latest/cello.js',
    attributionScript: 'https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js',
    productId: 'stage-app.guidde.com'
}

export const STIGG_CLIENT_KEY =
'$2b$10$2tLiBrjgm05excoNtNqQS.PvzVTP.dnsM3fL/Jk77AeedV6moEpIO:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const tapfiliateAccountId =
'47910-93439a'