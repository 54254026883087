import { useMemo } from 'react'

import { doc } from 'firebase/firestore'
import { useDocumentData } from 'react-firebase-hooks/firestore'

import { db } from 'modules'
import { useAuth } from 'hooks/use-auth'

export enum orgFeatures {
    hideInsightsLoadsAndWatchTime = 'hideInsightsLoadsAndWatchTime',
    showInsightsV2VideoDrawer = 'showInsightsV2VideoDrawer',
    disableExternalSharing = 'disableExternalSharing',
    overrideAllowedLanguages = 'overrideAllowedLanguages',
    useGpt4 = 'useGpt4',
    uploadSubtitles = 'uploadSubtitles',
    enableVoiceOver = 'enableVoiceOver',
    showQGRecordOnly = 'showQGRecordOnly',
    enableSketchedArrow = 'enableSketchedArrow',
    enableWixArrow = 'enableWixArrow',
    removeOverlayInDoc = 'removeOverlayInDoc',
    showCaptions = 'showCaptions',
    canvasV2 = 'canvasV2',
    allowMagicCapture = 'allowMagicCapture',
    showMagicCaptureLanguages = 'showMagicCaptureLanguages',
    withoutGradientBackground = 'withoutGradientBackground',
    allowDownloads = 'allowDownloads',
    showReferralLink = 'showReferralLink',
    withoutEndingScreenFooter = 'withoutEndingScreenFooter',
    addMusicByDefault = 'addMusicByDefault',
    hideVideoReactions = 'hideVideoReactions',
    hideBrowserBarUrl = 'hideBrowserBarUrl',
    hideButtonShareInEmbed = 'hideButtonShareInEmbed',
    disableOpenAI = 'disableOpenAI',
    hideNewPublicEmbedEnding = 'hideNewPublicEmbedEnding',
    hidePublicGetStartedBanner = 'hidePublicGetStartedBanner',
    disableImageElements = 'disableImageElements',
    showWizard = 'showWizard',
    showExtWizard = 'showExtWizard',
    showSpacesInsteadOfHome = 'showSpacesInsteadOfHome',
    showPublishPopup = 'showPublishPopup',
    enableInviteOnlyWorkspace = 'enableInviteOnlyWorkspace',
    tryIdeal4k = 'tryIdeal4k',
    enableFPSConfig = 'enableFPSConfig',
    enableTranslateQuotedText = 'enableTranslateQuotedText',
    disableInlineEdit = 'disableInlineEdit',
    captureVideoDuringQG = 'captureVideoDuringQG',
    hidePauseScreen = 'hidePauseScreen',
    showGButtonAsIconInEmbed = 'showGButtonAsIconInEmbed',
    pillarboxWhiteFill = 'pillarboxWhiteFill',
    showGtvExtensionWidget = 'showGtvExtensionWidget',
    showGtvSdkWidget = 'showGtvSdkWidget',
    enableQGFromVideo = 'enableQGFromVideo',
    enableQGFromExtScreenCapture = 'enableQGFromExtScreenCapture',
    showGtvQuickSearch = 'showGtvQuickSearch',
    fixedRecordingDimensions = 'fixedRecordingDimensions',
    enableAutomatedVideoStep = 'enableAutomatedVideoStep',
    enablePVC2 = 'enablePVC2'
}

export enum globalFeatures {
    hideBillingTable = 'hideBillingTable',
    enableNgnixLinks = 'enableNgnixLinks',
    useTeams = 'useTeams',
    openAIStatusHook = 'openAIStatusHook',
    qgProcessingBlacklist = 'qgProcessingBlacklist',
    doNotUseCustomSubdomain = 'doNotUseCustomSubdomain',
    enableRabbitMQ = 'enableRabbitMQ',
    enableMongoSync = 'enableMongoSync',
    getHomePlaybooksMongo = 'getHomePlaybooksMongo',
    downloadWithSubtitles = 'downloadWithSubtitles',
    showDictionary = 'showDictionary',
    enableVideoTrim = 'enableVideoTrim',
    extNoUrlSwitchInIframes = 'extNoUrlSwitchInIframes',
    enableImageAsBackground = 'enableImageAsBackground',
    enableAsyncSubtitles = 'enableAsyncSubtitles',
    enableCaptionsEditor = 'enableCaptionsEditor',
    qgPubSub = 'qgPubSub',
    enableNewShareToSpaceDialog = 'enableNewShareToSpaceDialog'
}

const AllFeatureFlags = { ...orgFeatures, ...globalFeatures }

type AllFeatureFlagsKeys = keyof typeof AllFeatureFlags

export const flagTooltips: { [key in AllFeatureFlagsKeys]: string } = {
    enableNewShareToSpaceDialog: 'Flag ON - enable new share to space dialog with folders',
    enablePVC2: 'Flag ON - enable addition of recording events in the initial QG creation',
    enableQGFromVideo:
        'Flag ON - Once video is uploaded, we will generate a QG instead of playbook',
    enableQGFromExtScreenCapture:
        'Flag ON - Once ext screen capture is finished, we will generate a QG instead of playbook',
    hideBillingTable: 'Flag ON - billing table is hidden in account settings page',
    showGButtonAsIconInEmbed: 'Flag ON- G button is shown as an icon in embed player',
    hideInsightsLoadsAndWatchTime:
        'Flag ON - video loads, avg. watch time/% charts are hidden(for workspaces with primary traffic from ext/sdk)',
    showInsightsV2VideoDrawer:
        'Flag ON - enable Insights 2.0 drawer inside video details pages instead of activity feed',
    disableExternalSharing:
        'Flag ON - public visibility videos, connected spaces and sharing videos with external domains are blocked',
    getHomePlaybooksMongo: 'FF ON - search playbook from MongoDB',
    overrideAllowedLanguages:
        'Flag ON - override rtdb config of allowedLanguages and put the vtt url in playbook entity',
    uploadSubtitles: 'Flag ON - upload subtitles is enabled for regular videos',
    enableVoiceOver: 'should be removed',
    showQGRecordOnly: 'Flag ON - only QG recordings are available to the extension',
    enableSketchedArrow: 'Flag ON - sketched arrow is enabled',
    enableWixArrow: 'Flag ON - Wix arrow is enabled',
    removeOverlayInDoc: 'Flag ON - overlay is not added to doc screenshot',
    showCaptions: 'Flag ON - subtitles are visible in QG editor and in view by default',
    canvasV2: 'should be removed',
    allowMagicCapture: 'Flag ON - magic capture button is visible in extension recording panel',
    showMagicCaptureLanguages: 'should be removed',
    withoutGradientBackground: 'Flag ON - background gradient is not added to QG canvas',
    allowDownloads: 'Flag ON - downloads are enabled in share dialog',
    showReferralLink: 'Flag ON - "Share & Earn" button is visible',
    disableImageElements: 'Flag ON - we will not show the above elements in the image dropdown.',
    withoutEndingScreenFooter:
        'Flag ON - "Capture a video like this one in seconds" banner is visible in ending screen in embed and public view',
    addMusicByDefault: 'ON - music is added by default on QG creation',
    hideVideoReactions: 'ON - helpful+not-helpful buttons are hidden in video player',
    hideBrowserBarUrl: 'Flag ON - browser bar is hidden on QG creation',
    hideButtonShareInEmbed: 'Flag ON - share button is hidden in embed player',
    disableOpenAI: 'Flag ON - openAI is not called on QG creation',
    hideNewPublicEmbedEnding: 'Flag ON - ending screen in embed and public view is hidden',
    hidePublicGetStartedBanner: 'Flag ON - "Get Started" button is hidden in public view',
    showWizard: 'Flag ON - wizard is visible in webapp',
    showExtWizard: 'Flag ON - wizard is visible in extension',
    showSpacesInsteadOfHome: 'Flag ON - default home page is spaces tab',
    showPublishPopup: 'should be removed',
    enableInviteOnlyWorkspace: 'should be removed',
    tryIdeal4k: 'Flag ON - support high frame rate (60 fps)',
    enableFPSConfig: 'Flag ON  - FPS (Frames Per Second) config is enabled in db',
    enableTranslateQuotedText: 'Flag ON - quoted words are translated in QG Auto-Translate',
    disableInlineEdit: 'Flag ON - doc inline edit is disabled',
    enableNgnixLinks: 'should be removed after SEO backling task',
    useTeams: 'Flag ON - workspaces are enabled',
    useGpt4: 'Flag ON- GPT Turbo 4 model is used',
    openAIStatusHook: 'pending Nadav',
    qgProcessingBlacklist: 'pending Nadav',
    doNotUseCustomSubdomain: 'Flag ON - custom subdomains are not enabled',
    enableRabbitMQ: 'pending Nadav',
    enableMongoSync: 'Flag ON - mongoDB clusters are enabled',
    downloadWithSubtitles: 'Flag ON - download with subtitles button is visible',
    enableVideoTrim: 'Flag ON - video trim is visible in QG editor (control panel)',
    showDictionary: 'Flag ON - dictionary is visible in account settings page',
    captureVideoDuringQG: 'Flag ON - video is captured during QG recording',
    hidePauseScreen: 'Flag ON - hide sign-up overlay that appears on pause',
    extNoUrlSwitchInIframes:
        'Flag ON - ext does not send extra "switch to" step when click happened inside the iframe',
    pillarboxWhiteFill:
        'Flag ON - change black pillarbox to white fill in the video player and editor. (will be applied on newly saved steps only)',
    enableImageAsBackground: 'Flag ON - image as background is enabled (editor + brand-kit)',
    showGtvExtensionWidget:
        'Flag ON - enable Guidde TV feature in Integrations settings and extension',
    showGtvSdkWidget: 'Flag ON - enable Guidde TV feature in Integrations settings and SDK',
    showGtvQuickSearch: 'Flag ON - replace library to widget in ext Quick search action feature',
    fixedRecordingDimensions: 'Flag ON - video will be recorded in 1920X1080',
    enableAsyncSubtitles: 'Flag ON - enable async subtitles processing while creating s2t and t2s',
    enableAutomatedVideoStep: 'Flag ON - enable automated video step',
    enableCaptionsEditor: 'Flag ON - add new button to open captions editor panel in QG editor',
    qgPubSub: 'Flag ON - use pub/sub for QG processing'
}

type FeatureFlagsType<T extends string> = {
    [key in T]?: boolean
} & { isLoading: boolean }

// orgId can be any organizationId, not only your own.
// SuperAdmin has ability to see/change featureFlags of any organization
export const useOrgFeatureFlags = (orgId?: string) => {
    const auth = useAuth()

    const organizationId = orgId || auth.orgId

    const [featureFlags, isLoading] = useDocumentData(
        doc(db, `featureFlags/${organizationId || 'globalFeatureFlags'}`)
    )

    return useMemo(
        () => ({
            ...Object.fromEntries(
                Object.keys(orgFeatures).map(feature => [feature, featureFlags?.[feature]])
            ),
            isLoading
        }),
        [featureFlags, isLoading]
    ) as FeatureFlagsType<orgFeatures>
}

export const useGlobalFeatureFlags = () => {
    const [featureFlags, isLoading] = useDocumentData(doc(db, `featureFlags/globalFeatureFlags`))

    return useMemo(
        () => ({
            ...Object.fromEntries(
                Object.keys(globalFeatures).map(feature => [feature, featureFlags?.[feature]])
            ),
            isLoading
        }),
        [featureFlags, isLoading]
    ) as FeatureFlagsType<globalFeatures>
}

export type OrgFeatureFlagType = keyof typeof useOrgFeatureFlags
export type GlobalFeatureFlagType = keyof typeof useGlobalFeatureFlags
