import { Fragment, memo } from 'react'
import { generatePath } from 'react-router-dom'

import { Checkbox } from '@guidde/design-system'

import { Box, Divider, Stack } from '@mui/material'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { faFolderPlus, faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'

import { type PlaybookModeType, type ShareableSpacesType } from 'app/types'
import { paths } from 'app/paths'

import { type CreateFolderResponse } from 'UI/Components/Dialogs/shareToDialogs/NewShareToSpaceDialog'
import { SPACE_GROUP_VALUES } from 'UI/Components/Dialogs/ShareDialog'

import { CustomTreeItem, actionId, openOnlyId } from './CustomTreeItem'
import { Folders } from './Folders'
import { CreateNewTargetInput } from './CreateNewTargetInput'
import { ActionIcon } from './ActionIcon'

import { type TSelectedData, getNewFolderButtonTooltipText, getTooltipText } from '../utils'
import { openLink } from 'modules'
import { maxFolderNameLength } from 'UI/Components'

type Props = {
    spaces: ShareableSpacesType['spaces']
    selectedData: TSelectedData
    isMultiplePlaybook: boolean
    playbookMode?: PlaybookModeType
    onCreateNewFolder: (data: {
        name: string
        spaceId: string
        parentFolderId: string
    }) => Promise<CreateFolderResponse | undefined>
    onShowFolderInputClick: (spaceId: string) => void
    onCloseNewFolderInput: () => void
    onToggleNewFolderInput: (folderId: string) => void
    onToggleFolderCheckbox: (folderId: string, spaceId: string) => void
    toggleSpaceSelect: (spaceId: string) => void
}

export const SpacesListWithFolders = memo(
    ({
        spaces,
        selectedData,
        isMultiplePlaybook,
        playbookMode,
        onCreateNewFolder,
        onShowFolderInputClick,
        onCloseNewFolderInput,
        toggleSpaceSelect,
        onToggleNewFolderInput,
        onToggleFolderCheckbox
    }: Props) => {
        const handleOpenFolder = (spaceId: string, folderIds: Array<string>) => {
            const [folderId, nestedFolderId, nestedFolderId2] = folderIds

            const pathname = generatePath(paths.spaceLibraryFolder, {
                spaceId,
                folderId,
                nestedFolderId,
                nestedFolderId2
            })

            openLink(pathname)
        }

        const handleOpenSpace = (spaceId: string) => {
            const pathname = generatePath(paths.spaceLibrary, { spaceId })

            openLink(pathname)
        }

        if (spaces?.length < 1 || !Object.values(selectedData).length) return null

        return (
            <SimpleTreeView>
                {spaces.map(space => {
                    const isConnectedFolder = space.id === SPACE_GROUP_VALUES.CONNECTED
                    const isSelectedSpace = selectedData[space.id]?.isSelected

                    const spaceFolders = selectedData[space.id]?.folders || []

                    const getSelectedFolderData = (folderId: string) => {
                        return spaceFolders.find(folder => folder.id === folderId) || null
                    }

                    const isSomeSpaceFolderSelected = spaceFolders.some(folder => folder.isSelected)
                    const showCreateFolderInput = selectedData[space.id]?.showNewFolderInput

                    return (
                        <Fragment key={space.id}>
                            <CustomTreeItem
                                itemId={space.id}
                                collapse={!space.folders?.length}
                                labelStyles={{
                                    fontSize: 14,
                                    fontWeight: 600
                                }}
                                hideActionControls={isConnectedFolder}
                                actionControls={
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={0.5}
                                        height="100%"
                                    >
                                        <ActionIcon
                                            id={actionId}
                                            tooltip="Open Space"
                                            onClick={() => handleOpenSpace(space.id)}
                                            icon={faArrowUpRightFromSquare}
                                            disabled={space.isDisabled}
                                        />

                                        <ActionIcon
                                            id={openOnlyId}
                                            tooltip={getNewFolderButtonTooltipText({
                                                isMaxLevelOfNesting: false,
                                                isSomeFolderSelected: isSomeSpaceFolderSelected,
                                                isMultiplePlaybook,
                                                playbookMode
                                            })}
                                            icon={faFolderPlus}
                                            disabled={isSomeSpaceFolderSelected || space.isDisabled}
                                            onClick={() => onShowFolderInputClick(space.id)}
                                        />
                                    </Stack>
                                }
                                isRoot={true}
                                padding="6px 12px 6px 0"
                                label={
                                    <Checkbox
                                        checked={isSelectedSpace}
                                        onChange={() => toggleSpaceSelect(space.id)}
                                        indeterminate={false}
                                        disabled={isSomeSpaceFolderSelected || space.isDisabled}
                                        label={space.name}
                                        disabledReason={
                                            isSomeSpaceFolderSelected
                                                ? getTooltipText({
                                                      isMultiplePlaybook,
                                                      playbookMode
                                                  })
                                                : ''
                                        }
                                    />
                                }
                            >
                                {showCreateFolderInput && (
                                    <Box my={1}>
                                        <CreateNewTargetInput
                                            onCreate={async name => {
                                                await onCreateNewFolder({
                                                    name,
                                                    spaceId: space.id,
                                                    parentFolderId: ''
                                                })
                                            }}
                                            maxLength={maxFolderNameLength}
                                            onDiscard={onCloseNewFolderInput}
                                            target="folder"
                                        />
                                    </Box>
                                )}
                                <Folders
                                    folders={space.folders || []}
                                    getSelectedFolderData={getSelectedFolderData}
                                    onToggleFolderCheckbox={folder => {
                                        onToggleFolderCheckbox(space.id, folder.id)
                                    }}
                                    shouldDisableFolderSelect={isSomeSpaceFolderSelected}
                                    onToggleNewFolderInput={onToggleNewFolderInput}
                                    onCreateNewFolder={async (name, parentFolderId = '') => {
                                        await onCreateNewFolder({
                                            name,
                                            parentFolderId,
                                            spaceId: space.id
                                        })
                                    }}
                                    isSpaceDisabled={space.isDisabled}
                                    onOpenFolder={foldersIds => {
                                        handleOpenFolder(space.id, foldersIds)
                                    }}
                                    isMultiplePlaybook={isMultiplePlaybook}
                                    playbookMode={playbookMode}
                                />
                            </CustomTreeItem>
                            <Divider sx={theme => ({ borderColor: theme.palette.grey[200] })} />
                        </Fragment>
                    )
                })}
            </SimpleTreeView>
        )
    }
)
